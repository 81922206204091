<template>
  <travio-center-container grid-width='1/2' :pageTitle="'Manage Translations for ' + translationName">
    <vx-card>
     
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <p class="text-sm"><strong>Description</strong></p>
          <p class="mt-2">{{ translationDescription }}</p>
        </div>
        
        <div class="vx-col w-full mt-6">
          <p class="text-sm"><strong>Default Value</strong></p>
          <p class="mt-2">{{ translationDefaultValue }}</p>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="translationList"
        :gridOptions="gridOptions"
        :rowSelection="rowSelection"
        :pagination="false"
        :suppressPaginationPanel="true"
        :style="{ height:'300px' }"
      >
      </ag-grid-vue>
      
      <div class="flex flex-wrap justify-end">
        <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
        <vs-button @click='onSubmit' class="mt-4">Save</vs-button>
      </div>

    </vx-card>
  </travio-center-container>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererTranslationValue from './cell-renderers/CellRendererTranslationValue.vue'
import Vue from 'vue'

export default {
  components: {
    AgGridVue,
    CellRendererTranslationValue
  },
  props: {
    applicationId: { required: true },
    translationId: { required: true }
  },
  data () {
    return {
      translationName: '',
      translationDescription: '',
      translationDefaultValue: '',
      translationList: [],
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererTranslationValue
      },
      hasLoaded: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    this.$http.get(`/api/translations/apps/${this.applicationId}/id/${this.translationId}`)
    .then(response => {
        this.translationList = response.data.translations
        this.translationName = response.data.name,
        this.translationDescription = response.data.description
        this.translationDefaultValue = response.data.defaultValue
        this.hasLoaded = true
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  

   
  },
  beforeMount() {
     this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'translationId', hide: true },
      { field: 'appId', hide: true },
      { field: 'value', hide: true },
      { headerName: 'Language', field: 'languageCode', sortable: true, width: 130, suppressSizeToFit: true },
      { headerName: 'Value', 
        sortable: false,
        suppressSizeToFit: false,
        width: 300,
        cellRendererFramework: Vue.extend(CellRendererTranslationValue) 
      }
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }
    this.rowSelection = 'single';


  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onSubmit () {
      this.$vs.loading()
      this.$http.post(
        `/api/translations/apps/${this.applicationId}/id/${this.translationId}`,
        { translations: this.translationList })
        .then(response => {
          this.$_notifySuccess('Translation successfully saved');
          // Reset dirty settings to prevent popup asking for unsaved changes

          // this.settings = this.$_.map(this.settings, el => {
          //   el.isDirty = false
          //   return el;
          // })

          this.$router.push({name: 'application-translations', 
            params: { applicationId: this.applicationId }
          })
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close())
    },
    handleCancel () {
      this.$router.push({name: 'application-translations', 
        params: { applicationId: this.applicationId }
      })
    },
   
  }
}
</script>

<style>

</style>